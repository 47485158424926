import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-top"
  }, [_c('div', {
    staticClass: "height41"
  }, [_c('div', {
    staticClass: "v2-g-w1200 v2-g-flex-row v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "top-left"
  }, [_c('span', {
    staticClass: "welcome"
  }, [_vm._v("欢迎来普健商城！")]), _c('div', {
    staticClass: "loged"
  }, [_c('span', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/sy_hai.png")
    }
  }), _vm.isExternal ? _c('span', {
    staticClass: "v2-customer"
  }, [_vm._v("[" + _vm._s(_vm.userName) + "]")]) : _c('span', {
    staticClass: "v2-customer"
  }, [_vm._v("[" + _vm._s(_vm.loginCompanyName) + "] 【切换】")])]), _c('span', {
    staticClass: "to-login",
    on: {
      "click": _vm.toLogOut
    }
  }, [_vm._v("退出")]), !_vm.isExternal ? _c('ul', {
    staticClass: "user-list"
  }, [_c('li', {
    staticClass: "cus-search"
  }, [_c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchName,
      expression: "searchName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "输入客户名称查询"
    },
    domProps: {
      "value": _vm.searchName
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.searchName = $event.target.value;
      }, _vm.quickSearch]
    }
  })])]), _vm.copyUserList.length == 0 ? [_vm._m(0)] : _vm._e(), _vm._l(_vm.copyUserList, function (item, index) {
    return _c('li', {
      key: index,
      class: item.name === _vm.userName ? 'selected' : '',
      attrs: {
        "title": item.name
      },
      on: {
        "click": function click($event) {
          item.name === _vm.userName ? undefined : _vm.onCheckUser(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), _c('b-loading', {
    attrs: {
      "is-full-page": false,
      "can-cancel": false
    },
    model: {
      value: _vm.isLoading,
      callback: function callback($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "v2-g-flex-row v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "v2-link1"
  }, [_c('a', {
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function click($event) {
        return _vm.jump('v2-electronicInvoiceList');
      }
    }
  }, [_vm._v("发票下载")]), _c('i'), _c('a', {
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function click($event) {
        return _vm.jump('v2-download');
      }
    }
  }, [_vm._v("资料下载")]), _c('i'), _c('a', {
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function click($event) {
        return _vm.jump('v2-information');
      }
    }
  }, [_vm._v("个人中心")]), _c('i')]), _c('div', {
    staticClass: "v2-link2"
  }, [_vm._m(1), _c('a', {
    attrs: {
      "href": "javascript:/"
    },
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.isShowCode = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.isShowCode = false;
      }
    }
  }, [_c('i', {
    staticClass: "iconfont icon-mobile1"
  }), _vm._v("手机版")]), _vm.isShowCode ? _c('div', {
    staticClass: "code-wrap"
  }, [_c('span', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeFun.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "code-cont"
  }, [_c('img', {
    attrs: {
      "src": _vm.qrCodeUrl
    }
  }), _c('p', {
    staticClass: "info"
  }, [_vm._v("扫码安装普健商城APP")])])]) : _vm._e()])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', {
    staticClass: "nodata"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/nodata.png")
    }
  }), _c('span', [_vm._v("暂无相关商品")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('a', {
    attrs: {
      "href": "/普健.url"
    }
  }, [_c('i', {
    staticClass: "iconfont icon-all-fill"
  }), _vm._v("添加桌面")]);
}];
export { render, staticRenderFns };